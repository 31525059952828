import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import { ManagerContextProvider } from "./context/managerContext";

ReactDOM.render(
  <React.StrictMode>
    <ManagerContextProvider>
      <App />
    </ManagerContextProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
