import SidebarAdmin from "../../components/SidebarAdmin/SidebarAdmin";
import Navbar from "../../components/navbar/Navbar";
import "./shops.scss";
import Widget from "../../components/widget/Widget";
import Shop from "../../components/shop/shop";

const Shops = () => {
  return (
    <div className="home">
      <SidebarAdmin />
      <div className="homeContainer">
        {/* <Navbar /> */}
        <div className="widgets">
          <Widget type="earning" />
          <Widget type="balance" />
        </div>

        <div className="listContainer">
          <div className="listTitle">All Shops</div>
          <Shop />
        </div>
      </div>
    </div>
  );
};

export default Shops;
