import "./shop.scss";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import Button from "@mui/material/Button";
import firebase from "../../utils/firebase";
import { Check } from "@mui/icons-material";
import { Checkbox } from "@mui/material";
import TextField from "@mui/material/TextField";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import LocationPiker from "../../components/locationPicker/locationPiker";
import { Typography } from "@mui/material";
import Sheet from "@mui/joy/Sheet";
import Switch from "@mui/joy/Switch";
import Select from "react-select";

const Shop = () => {
  const options = [
    { label: "Austria", value: "EUR" },
    { label: "Belgium", value: "EUR" },
    { label: "Canada", value: "CAD" },
    { label: "Denmark", value: "DKK" },
    { label: "Egypt", value: "EGP" },
    { label: "Finland", value: "EUR" },
    { label: "France", value: "EUR" },
    { label: "Germany", value: "EUR" },
    { label: "Greece", value: "EUR" },
    { label: "Hongkong", value: "HKD" },
    { label: "Iceland", value: "ISK" },
    { label: "India", value: "INR" },
    { label: "Ireland", value: "EUR" },
    { label: "Italy", value: "EUR" },
    { label: "Japan", value: "JPY" },
    { label: "Kuwait", value: "KWD" },
    { label: "Liechtenstein", value: "CHF" },
    { label: "Luxembourg", value: "EUR" },
    { label: "Monaco", value: "EUR" },
    { label: "Netherlands", value: "EUR" },
    { label: "Norway", value: "NOK" },
    { label: "Poland", value: "PLN" },
    { label: "Portugal", value: "EUR" },
    { label: "Qatar", value: "QAR" },
    { label: "Russia", value: "RUB" },
    { label: "Saudi Arabia", value: "SAR" },
    { label: "Singapore", value: "SGD" },
    { label: "Spain", value: "EUR" },
    { label: "Sweden", value: "SEK" },
    { label: "Switzerland", value: "CHF" },
    { label: "Turkey", value: "TRY" },
    { label: "UAE", value: "AED" },
    { label: "United Kingdom", value: "GBP" },
    { label: "USA", value: "USD" },
  ];
  const [selectedOption, setSelectedOption] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedShop, setSelectedShop] = useState({});
  const [edit, setEdit] = useState(false);
  const [shops, setShops] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [shopLogo, setShopLogo] = useState("");
  const [shopId, setShopId] = useState("");
  const [shopName, setShopName] = useState("");
  const [email, setEmail] = useState("");
  const [menuItems, setMenuItems] = useState([{ item: "", price: "" }]);
  const [stylists, setStylists] = useState([{ name: "", image: null }]);
  const [charges, setCharges] = useState([{ name: "", price: "" }]);
  const [reviews, setReviews] = useState(0);
  const [rating, setRating] = useState(5);
  const [checked, setChecked] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [two, setTwo] = useState("");
  const [three, setThree] = useState("");
  const [four, setFour] = useState("");
  const [status, setStatus] = useState("");
  const [vat, setVat] = useState(0);
  console.log("log", menuItems);
  const fetchData = async () => {
    try {
      const db = firebase.firestore();
      const unsubscribe = db
        .collection("Shops") // Replace with the actual collection name
        .onSnapshot((querySnapshot) => {
          const data = [];
          querySnapshot.forEach((documentSnapshot) => {
            data.push({
              id: documentSnapshot.id,
              ...documentSnapshot.data(),
            });
          });
          setShops(data);
        });

      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching collection:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const handleSelectRow = (item) => {
    const isSelected = selectedRows.includes(item);
    setSelectedRows(
      isSelected
        ? selectedRows.filter((r) => r !== item)
        : [...selectedRows, item]
    );
  };

  const isSelected = (item) => selectedRows.includes(item);

  const handleCreateNewArray = async () => {
    // Create a new array containing only the selected rows
    const selectedData = shops.filter((item) => selectedRows.includes(item));
    const selectedIds = selectedData.map((item) => item.shopId);
    await firebase.firestore().collection("Shoplist").doc("1").set({
      shopList: selectedIds,
    });
  };
  const deleteShopHandler = async (item) => {
    await firebase
      .firestore()
      .collection("Shops")
      .doc(item.shopId)
      .delete()
      .then(() => {
        console.log("shop deleted successfully");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  //edit
  const updateShopHandler = async () => {
    if (!shopLogo) {
      alert("Please add shop logo!");
      return;
    }
    if (!shopId) {
      alert("Please enter the shop Id!");
      return;
    }
    if (!shopName) {
      alert("Please enter the shop Name!");
      return;
    }
    if (!email) {
      alert("Please enter the email address!");
      return;
    }
    if (menuItems[0].item === "" || menuItems[0].price === "") {
      alert("Please add atleast one menu item!");
      return;
    }
    if (stylists[0].name == "" || stylists[0].image === null) {
      alert("Please add atleast one stylist!");
      return;
    }
    if (charges[0].name == "" || charges[0].price === "") {
      alert("Please add atleast one charges item!");
      return;
    }
    if (selectedOption === null) {
      alert("Please select currency!");
      return;
    }
    if (!vat) {
      alert("Please add vat!");
      return;
    }
    if (checked && !two) {
      alert("Please enter two times / month subscription price!");
      return;
    }
    if (checked && !three) {
      alert("Please enter three times / month subscription price!");
      return;
    }
    if (checked && !four) {
      alert("Please enter four times / month subscription price!");
      return;
    }
    if (!selectedLocation) {
      alert("Please pick the shop location!");
      return;
    }
    await firebase.firestore().collection("Shops").doc(shopId).update({
      shopId: shopId,
      email: email,
      shopLogo: shopLogo,
      shopName: shopName,
      details: menuItems,
      charges: charges,
      stylists: stylists,
      dsp: checked,
      twoTimes: two,
      threeTimes: three,
      fourTimes: four,
      shopLocation: selectedLocation,
      reviews: reviews,
      rating: rating,
      currency: selectedOption,
      vat: vat,
      status: status,
    });
  };

  const handleShopLogo = async (file) => {
    // Upload image to Firebase Storage
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(file.name);
    await imageRef
      .put(file)
      .then((res) => {
        console.log("done", res);
      })
      .catch((err) => {
        console.log("err", err);
      });

    // Get the image URL
    const imageUrl = await imageRef.getDownloadURL();
    setShopLogo(imageUrl);
  };
  const handleAddMenu = () => {
    const newItems = [...menuItems, { item: "", price: "" }];
    setMenuItems(newItems);
  };

  const handleInputChangeMenu = (index, field, value) => {
    const newItems = [...menuItems];
    newItems[index][field] = value;
    setMenuItems(newItems);
  };

  const handleAddStylist = () => {
    const newItems = [...stylists, { name: "", image: null }];
    setStylists(newItems);
  };

  const handleStylistNameChange = (index, value) => {
    const newItems = [...stylists];
    newItems[index].name = value;
    setStylists(newItems);
  };

  const handleStylistImageChange = async (index, file) => {
    const newItems = [...stylists];

    // Upload image to Firebase Storage
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(file.name);
    await imageRef
      .put(file)
      .then((res) => {
        console.log("done", res);
      })
      .catch((err) => {
        console.log("err", err);
      });

    // Get the image URL
    const imageUrl = await imageRef.getDownloadURL();

    newItems[index].image = imageUrl;
    setStylists(newItems);
  };
  const handleChargeInputChange = (index, field, value) => {
    const newItems = [...charges];
    newItems[index][field] = value;
    setCharges(newItems);
  };
  const handleAddCharges = () => {
    const newItems = [...charges, { name: "", price: "" }];
    setCharges(newItems);
  };
  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Select</TableCell>
            <TableCell className="tableCell">Shop</TableCell>
            <TableCell className="tableCell">Email</TableCell>
            <TableCell className="tableCell">DSP</TableCell>
            <TableCell className="tableCell">Status</TableCell>
            <TableCell className="tableCell">Reviews</TableCell>
            <TableCell className="tableCell">Rating</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shops.map((item) => (
            <TableRow key={item.shopId}>
              <TableCell className="tableCell">
                <Checkbox
                  className="checkbox"
                  checked={isSelected(item)}
                  onClick={() => handleSelectRow(item)}
                  icon={<span className="checkbox-icon"></span>}
                />
              </TableCell>
              <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img src={item?.shopLogo} alt="" className="image" />
                  {item.shopName}
                </div>
              </TableCell>
              <TableCell className="tableCell">{item.email}</TableCell>

              <TableCell className="tableCell">
                {item.dsp ? "Included" : "Not Include"}
              </TableCell>
              <TableCell className="tableCell">{item.status}</TableCell>
              <TableCell className="tableCell">{item.reviews}</TableCell>
              <TableCell className="tableCell">{item.rating}</TableCell>
              <TableCell className="tableCell">
                <Button
                  variant="outlined"
                  style={{ width: "40%", fontSize: 10 }}
                  onClick={() => {
                    setSelectedShop(item);
                    setShopLogo(item.shopLogo);
                    setShopId(item.shopId);
                    setShopName(item.shopName);
                    setEmail(item.email);
                    setMenuItems(item.details);
                    setStylists(item.stylists);
                    setCharges(item.charges);
                    setChecked(item.dsp);
                    setTwo(item.twoTimes);
                    setThree(item.threeTimes);
                    setFour(item.fourTimes);
                    setReviews(item.reviews);
                    setRating(item.rating);
                    setSelectedLocation(item.shopLocation);
                    setStatus(item.status);
                    setEdit(true);
                    setVat(item.vat);
                    setSelectedOption(item.currency);
                  }}
                >
                  Edit
                </Button>
              </TableCell>
              <TableCell className="tableCell">
                <Button
                  variant="outlined"
                  style={{ width: "40%", fontSize: 10 }}
                  onClick={() => {
                    deleteShopHandler(item);
                  }}
                >
                  Delete
                </Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Button
        style={{
          width: "30%",
          marginLeft: "65%",
          marginTop: 10,
          marginBottom: 10,
        }}
        variant="contained"
        onClick={handleCreateNewArray}
      >
        Add Selected Shops to shop list
      </Button>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-desc"
        open={edit}
        onClose={() => setEdit(false)}
        hideBackdrop={true}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          overflow: "scroll",
          backgroundColor: "white",
          marginTop: 0,
        }}
      >
        <div style={{ maxHeight: 500 }} className="listContainer">
          <div style={{ textAlign: "center" }} className="listTitle">
            <h1 style={{ textAlign: "center", color: "black" }}>Edit Shop</h1>
          </div>
          <Button
            variant="contained"
            style={{
              width: "50%",
              marginLeft: "20%",
              marginTop: 30,
              backgroundColor: "red",
            }}
            onClick={() => {
              setEdit(false);
            }}
          >
            Go Back
          </Button>
          <Typography
            style={{
              marginLeft: "40%",
              marginTop: 10,
              marginBottom: 10,
              color: "red",
              fontWeight: "bold",
            }}
            level="h1"
          >
            Shop Logo
          </Typography>

          <TextField
            type="file"
            style={{
              width: "50%",
              marginLeft: "20%",
              marginTop: 10,
            }}
            id="outlined-basic"
            variant="outlined"
            accept="image/*"
            onChange={(e) => handleShopLogo(e.target.files[0])}
          />
          <TextField
            style={{
              width: "50%",
              marginLeft: "20%",
              marginTop: 10,
            }}
            id="outlined-basic"
            label="Shop Id"
            variant="outlined"
            onChange={(event) => {
              setShopId(event.target.value);
            }}
            InputProps={{
              readOnly: true,
            }}
            value={shopId}
            contentEditable={false}
          />
          <TextField
            style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
            id="outlined-basic"
            label="Shop Name"
            variant="outlined"
            onChange={(event) => {
              setShopName(event.target.value);
            }}
            value={shopName}
          />
          <TextField
            style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
            id="outlined-basic"
            label="Email Address"
            variant="outlined"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            InputProps={{
              readOnly: true,
            }}
            value={email}
          />
          <Typography
            style={{
              marginLeft: "40%",
              marginTop: 10,
              marginBottom: 10,
              color: "red",
              fontWeight: "bold",
            }}
            level="h2"
          >
            Add Menu
          </Typography>
          {menuItems.map((item, index) => {
            return (
              <div key={index} style={{ flexDirection: "row" }}>
                <TextField
                  style={{ width: "24%", marginLeft: "20%", marginTop: 10 }}
                  id="outlined-basic"
                  label="Menu Item"
                  variant="outlined"
                  onChange={(e) =>
                    handleInputChangeMenu(index, "item", e.target.value)
                  }
                  value={item.item}
                />
                <TextField
                  type="number"
                  style={{ width: "24%", marginLeft: "2%", marginTop: 10 }}
                  id="outlined-basic"
                  label="Price"
                  variant="outlined"
                  onChange={(e) =>
                    handleInputChangeMenu(index, "price", e.target.value)
                  }
                  value={item.price}
                />
              </div>
            );
          })}

          <Button
            onClick={handleAddMenu}
            style={{ width: "50%", marginLeft: "20%", marginTop: 20 }}
            variant="contained"
          >
            Add More Menu Items
          </Button>
          <Typography
            style={{
              marginLeft: "40%",
              marginTop: 10,
              marginBottom: 10,
              color: "red",
              fontWeight: "bold",
            }}
            level="h1"
          >
            Add Stylists
          </Typography>
          {stylists.map((item, index) => {
            return (
              <div key={index} style={{ flexDirection: "row" }}>
                <TextField
                  style={{ width: "24%", marginLeft: "20%", marginTop: 10 }}
                  id="outlined-basic"
                  label="Stylist Name"
                  variant="outlined"
                  value={item.name}
                  onChange={(e) =>
                    handleStylistNameChange(index, e.target.value)
                  }
                />
                <TextField
                  type="file"
                  style={{ width: "24%", marginLeft: "2%", marginTop: 10 }}
                  id="outlined-basic"
                  variant="outlined"
                  accept="image/*"
                  onChange={(e) =>
                    handleStylistImageChange(index, e.target.files[0])
                  }
                />
                {/* {item.image && <img src={item.image} alt="Item" style={{}} />} */}
              </div>
            );
          })}

          <Button
            onClick={handleAddStylist}
            style={{ width: "50%", marginLeft: "20%", marginTop: 20 }}
            variant="contained"
          >
            Add More Stylist
          </Button>
          <Typography
            style={{
              marginLeft: "40%",
              marginTop: 10,
              marginBottom: 10,
              color: "red",
              fontWeight: "bold",
            }}
            level="h1"
          >
            Add Charges
          </Typography>
          {charges.map((item, index) => {
            return (
              <div key={index} style={{ flexDirection: "row" }}>
                <TextField
                  style={{ width: "24%", marginLeft: "20%", marginTop: 10 }}
                  id="outlined-basic"
                  label="Name of charge"
                  variant="outlined"
                  onChange={(e) =>
                    handleChargeInputChange(index, "name", e.target.value)
                  }
                  value={item.name}
                />
                <TextField
                  type="number"
                  style={{ width: "24%", marginLeft: "2%", marginTop: 10 }}
                  id="outlined-basic"
                  label="Price"
                  variant="outlined"
                  onChange={(e) =>
                    handleChargeInputChange(index, "price", e.target.value)
                  }
                  value={item.price}
                />
              </div>
            );
          })}

          <Button
            onClick={handleAddCharges}
            style={{ width: "50%", marginLeft: "20%", marginTop: 20 }}
            variant="contained"
          >
            Add More Charges
          </Button>

          <div style={{ width: "50%", marginLeft: "20%", marginTop: 20 }}>
            <Switch
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
              color={checked ? "success" : "neutral"}
              variant={checked ? "solid" : "outlined"}
              endDecorator={
                checked
                  ? "Shop Included In DSP(drep Subscription Program)"
                  : "Shop Not Included In DSP(drep Subscription Program)"
              }
              slotProps={{
                endDecorator: {
                  sx: {
                    minWidth: 24,
                  },
                },
              }}
            />
          </div>
          <div style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}>
            <Select
              placeholder="Currency"
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              isSearchable={true}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.label + option.value}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: 55,
                }),
                menu: (provided, state) => ({
                  ...provided,
                  zIndex: 5, // or a higher value if necessary
                }),
              }}
            />
          </div>
          <TextField
            type="number"
            style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
            id="outlined-basic"
            variant="outlined"
            label="VAT"
            onChange={(event) => {
              setVat(event.target.value);
            }}
            value={vat}
          />
          {checked && (
            <div>
              <TextField
                type="number"
                style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
                id="outlined-basic"
                label="2 times/month price"
                variant="outlined"
                onChange={(event) => {
                  setTwo(event.target.value);
                }}
                value={two}
              />
              <TextField
                type="number"
                style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
                id="outlined-basic"
                label="3 times/month price"
                variant="outlined"
                onChange={(event) => {
                  setThree(event.target.value);
                }}
                value={three}
              />
              <TextField
                type="number"
                style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
                id="outlined-basic"
                label="4 times/month price"
                variant="outlined"
                onChange={(event) => {
                  setFour(event.target.value);
                }}
                value={four}
              />
            </div>
          )}
          <TextField
            type="number"
            style={{ width: "50%", marginLeft: "20%", marginTop: 20 }}
            id="outlined-basic"
            label="Shop Reviews"
            variant="outlined"
            onChange={(event) => {
              setReviews(event.target.value);
            }}
            value={reviews}
          />
          <TextField
            type="number"
            style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
            id="outlined-basic"
            label="Shop Ratings"
            variant="outlined"
            onChange={(event) => {
              setRating(event.target.value);
            }}
            value={rating}
          />
          <Button
            style={{
              display: "block",
              width: "50%",
              marginLeft: "20%",
              marginTop: 20,
            }}
            size="md"
            variant={"outlined"}
            color="success"
            onClick={() => {
              setOpen(true);
            }}
          >
            Pick up the shop location
          </Button>
          <Button
            variant="contained"
            style={{
              width: "50%",
              marginLeft: "20%",
              marginTop: 30,
              backgroundColor: "red",
            }}
            onClick={() => {
              updateShopHandler();
              setEdit(false);
            }}
          >
            Update Shop
          </Button>
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={open}
            onClose={() => setOpen(false)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Sheet
              variant="outlined"
              sx={{
                width: "70%",
                borderRadius: "md",
                p: 3,
                boxShadow: "lg",
                height: 500,
              }}
            >
              <ModalClose
                variant="outlined"
                sx={{
                  top: "calc(-1/4 * var(--IconButton-size))",
                  right: "calc(-1/4 * var(--IconButton-size))",
                  boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                  borderRadius: "50%",
                  bgcolor: "background.surface",
                }}
              />
              <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
              >
                Please the shop location from map:
              </Typography>
              <LocationPiker
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
              />
            </Sheet>
          </Modal>
        </div>
      </Modal>
    </TableContainer>
  );
};

export default Shop;
