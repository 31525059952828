import "./suggesstion.scss";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import firebase from "../../utils/firebase";

const Suggesstion = () => {
  const [suggesstions, setSuggesstions] = useState([]);
  console.log("sug", suggesstions);
  const fetchData = async () => {
    try {
      const db = firebase.firestore();
      const unsubscribe = db
        .collection("Suggestions") // Replace with the actual collection name
        .onSnapshot((querySnapshot) => {
          const data = [];
          querySnapshot.forEach((documentSnapshot) => {
            data.push({
              id: documentSnapshot.id,
              ...documentSnapshot.data(),
            });
          });

          setSuggesstions(data);
        });

      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching collection:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Customer</TableCell>
            <TableCell className="tableCell">Suggestion</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {suggesstions.map((item) => (
            <TableRow key={item.id}>
              <TableCell style={{ width: "15%" }}>
                <div className="cellWrapper">
                  <img
                    src={item?.user?.profileImage}
                    alt=""
                    className="image"
                  />
                  {item.user.name}
                </div>
              </TableCell>

              <TableCell className="tableCell">
                <p style={{ fontSize: 16, color: "orangered" }}>
                  {item?.suggestion}
                </p>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Suggesstion;
