import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./login.scss";
import firebase from "../../utils/firebase";
import { ManagerContext } from "../../context/managerContext";

const Login = (props) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [emailError, setEmailError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const { manager, dispatch } = useContext(ManagerContext);
  console.log("redux", manager);

  const navigate = useNavigate();

  const onButtonClick = async () => {
    // dispatch({ type: "SET_MANAGER", payload: { a: "done", b: "shooo" } });
    await firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        console.log("user", user.user.uid);
        if (user.user.uid === "6FJQlLLNbEOimkLZeG8nwN4LlMk1") {
          navigate("/shops");
        } else {
          dispatch({
            type: "SET_MANAGER",
            payload: { id: user.user.uid, email: user.user.email },
          });
          navigate("/orders");
        }
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div className={"mainContainer"}>
      <div className={"titleContainer"}>
        <div>Login</div>
      </div>
      <br />
      <div className={"inputContainer"}>
        <input
          value={email}
          placeholder="Enter your email here"
          onChange={(ev) => setEmail(ev.target.value)}
          className={"inputBox"}
        />
        <label className="errorLabel">{emailError}</label>
      </div>
      <br />
      <div className={"inputContainer"}>
        <input
          value={password}
          placeholder="Enter your password here"
          onChange={(ev) => setPassword(ev.target.value)}
          className={"inputBox"}
        />
        <label className="errorLabel">{passwordError}</label>
      </div>
      <br />
      <div className={"inputContainer"}>
        <input
          className={"inputButton"}
          type="button"
          onClick={onButtonClick}
          value={"Log in"}
        />
      </div>
    </div>
  );
};

export default Login;
