import "./widget.scss";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import AccountBalanceWalletOutlinedIcon from "@mui/icons-material/AccountBalanceWalletOutlined";
import ShoppingCartOutlinedIcon from "@mui/icons-material/ShoppingCartOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { useState, useContext, useEffect } from "react";
import firebase from "../../utils/firebase";
import { ManagerContext } from "../../context/managerContext";

const Widget = ({ type }) => {
  let data;
  const [orders, setOrders] = useState(0);
  const [subs, setSubs] = useState(0);
  const [shops, setShops] = useState(0);
  const [shopList, setShopList] = useState(0);
  const { manager } = useContext(ManagerContext);
  const fetchOrders = async () => {
    try {
      const db = firebase.firestore();
      const unsubscribe = db
        .collection("Orders") // Replace with the actual collection name
        .onSnapshot((querySnapshot) => {
          const data = [];
          querySnapshot.forEach((documentSnapshot) => {
            data.push({
              id: documentSnapshot.id,
              ...documentSnapshot.data(),
            });
          });
          const myOrders = data.filter((item) => {
            return item.shop.shopId === manager.id;
          });
          setOrders(myOrders.length);
        });

      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching collection:", error);
    }
  };
  const fetchSubs = async () => {
    try {
      const db = firebase.firestore();
      const unsubscribe = db
        .collection("Subscriptions") // Replace with the actual collection name
        .onSnapshot((querySnapshot) => {
          const data = [];
          querySnapshot.forEach((documentSnapshot) => {
            data.push({
              id: documentSnapshot.id,
              ...documentSnapshot.data(),
            });
          });
          const mySubs = data.filter((item) => {
            return item.shop.shopId === manager.id;
          });
          setSubs(mySubs.length);
        });

      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching collection:", error);
    }
  };
  const fetchShops = async () => {
    try {
      const db = firebase.firestore();
      const unsubscribe = db
        .collection("Shops") // Replace with the actual collection name
        .onSnapshot((querySnapshot) => {
          const data = [];
          querySnapshot.forEach((documentSnapshot) => {
            data.push({
              id: documentSnapshot.id,
              ...documentSnapshot.data(),
            });
          });

          setShops(data.length);
        });

      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching collection:", error);
    }
  };
  const fetchShopList = async () => {
    try {
      const db = firebase.firestore();
      const unsubscribe = db
        .collection("Shoplist")
        .doc("1") // Replace with the actual collection name
        .onSnapshot((querySnapshot) => {
          const data = querySnapshot.data();
          setShopList(data.shopList.length);
        });

      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching collection:", error);
    }
  };
  useEffect(() => {
    fetchOrders();
    fetchSubs();
    fetchShops();
    fetchShopList();
  }, []);

  switch (type) {
    case "user":
      data = {
        title: "TOTAL SUBSCRIPTIONS",
        isMoney: false,
        amount: subs,
        icon: (
          <PersonOutlinedIcon
            className="icon"
            style={{
              color: "crimson",
              backgroundColor: "rgba(255, 0, 0, 0.2)",
            }}
          />
        ),
      };
      break;
    case "order":
      data = {
        title: "TOTAL ORDERS",
        amount: orders,
        link: "View all orders",
        icon: (
          <ShoppingCartOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(218, 165, 32, 0.2)",
              color: "goldenrod",
            }}
          />
        ),
      };
      break;
    case "earning":
      data = {
        title: "Total Shops",
        amount: shops,

        icon: (
          <MonetizationOnOutlinedIcon
            className="icon"
            style={{ backgroundColor: "rgba(0, 128, 0, 0.2)", color: "green" }}
          />
        ),
      };
      break;
    case "balance":
      data = {
        title: "Total Shops In ShopList",
        amount: shopList,
        icon: (
          <AccountBalanceWalletOutlinedIcon
            className="icon"
            style={{
              backgroundColor: "rgba(128, 0, 128, 0.2)",
              color: "purple",
            }}
          />
        ),
      };
      break;
    default:
      break;
  }

  return (
    <div className="widget">
      <div className="left">
        <span className="title">{data.title}</span>
        <span className="counter">{data.amount}</span>
      </div>
      <div className="right">{data.icon}</div>
    </div>
  );
};

export default Widget;
