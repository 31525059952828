import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./adminOrders.scss";
import Widget from "../../components/widget/Widget";
import Table from "../../components/table/Table";
import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import firebase from "../../utils/firebase";
import { ManagerContext } from "../../context/managerContext";
import SidebarAdmin from "../../components/SidebarAdmin/SidebarAdmin";
import AdminOrdersComp from "../../components/adminOrders/AdminOrdersComp";

const AdminOrders = () => {
  const [status, setStatus] = useState();
  const { manager } = useContext(ManagerContext);

  return (
    <div className="home">
      <SidebarAdmin />
      <div className="homeContainer">
        <Navbar />

        <div className="listContainer">
          <div className="listTitle">Orders</div>
          <AdminOrdersComp />
        </div>
      </div>
    </div>
  );
};

export default AdminOrders;
