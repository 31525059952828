import "./adminOrdersComp.scss";
import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import firebase from "../../utils/firebase";
import { ManagerContext } from "../../context/managerContext";

const AdminOrdersComp = () => {
  const [orders, setOrders] = useState([]);
  const { manager } = useContext(ManagerContext);

  console.log("id", manager.id);
  console.log("or", orders);
  const fetchData = async () => {
    try {
      const db = firebase.firestore();
      const unsubscribe = db
        .collection("Orders")
        .orderBy("timeStamp")
        .onSnapshot((querySnapshot) => {
          const data = [];
          querySnapshot.forEach((documentSnapshot) => {
            data.push({
              id: documentSnapshot.id,
              ...documentSnapshot.data(),
            });
          });
          // Reverse the order if you want the latest orders first
          let orderArray = data.reverse();
          // Optionally, you can still limit the number of orders displayed
          let sliceArray = orderArray.slice(0, 5);
          setOrders(sliceArray);  // Update your state with the orders
        });

      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching collection:", error);
    }
  };


  useEffect(() => {
    fetchData();
  }, []);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleCancel = async (item) => {
    await firebase.firestore().collection("Orders").doc(item.orderId).update({
      status: "cancelled",
    });
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const assignStylistHander = async (stylist, item) => {
    console.log("sst", stylist, item.orderId);
    handleClose();
    await firebase.firestore().collection("Orders").doc(item.orderId).update({
      status: "assigned",
      stylist: stylist,
    });
  };
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit' };
    return date.toLocaleDateString('en-US', options);
  };
  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Customer</TableCell>
            <TableCell className="tableCell">From Subscription</TableCell>
            <TableCell className="tableCell">Service</TableCell>
            <TableCell className="tableCell">Total Amount</TableCell>
            <TableCell className="tableCell">Payment Method</TableCell>
            <TableCell className="tableCell">Location</TableCell>
            <TableCell className="tableCell">Address</TableCell>
            <TableCell className="tableCell">Stylist</TableCell>
            <TableCell className="tableCell">Phone Number</TableCell>
            <TableCell className="tableCell">Time</TableCell>
            <TableCell className="tableCell">Shop Name</TableCell>
            <TableCell className="tableCell">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((item) => (
            <TableRow key={item.shopId}>
              <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img
                    src={item?.user?.profileImage}
                    alt=""
                    className="image"
                  />
                  {item.user.name}
                </div>
              </TableCell>
              <TableCell className="tableCell">
                {item.fromSubscription ? "true" : "false"}
              </TableCell>

              <TableCell className="tableCell">
                {item.fromSubscription ? "Subscription" : item.menuItem.item}
              </TableCell>

              <TableCell className="tableCell">{item?.totalAmount}</TableCell>
              <TableCell className="tableCell">{item?.paymentMethod}</TableCell>
              <TableCell className="tableCell">
                <a
                  href={item?.user.locationLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  open location
                </a>
              </TableCell>
              <TableCell className="tableCell">
                <p style={{ fontSize: 9 }}>{item?.user.address}</p>
              </TableCell>
              <TableCell className="tableCell">
                {item.stylist.name ? item.stylist.name : "Not Specified"}
              </TableCell>
              <TableCell className="tableCell">
                {item.user.phoneNumber}
              </TableCell>

              <TableCell className="tableCell">
                {item.time}
              </TableCell>
              <TableCell className="tableCell">
                {item.shop.shopName}
              </TableCell>
              <TableCell className="tableCell">
                <span className={`status ${item.status}`}>{item.status}</span>
              </TableCell>

              <TableCell className="tableCell">
                {item.status === "requested" ? (
                  <>
                    <Button
                      style={{ width: "10%", fontSize: 10 }}
                      variant="outlined"
                      onClick={handleClick}
                    >
                      assign
                    </Button>

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {item.shop.stylists.map((stylist, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            console.log("it", item.orderId);
                            assignStylistHander(stylist, item);
                          }}
                        >
                          {stylist.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                ) : null}
              </TableCell>
              <TableCell className="tableCell">
                {item.status === "requested" ? (
                  <Button
                    style={{ width: "10%", fontSize: 10 }}
                    variant="outlined"
                    onClick={() => {
                      handleCancel(item);
                    }}
                  >
                    Cancel
                  </Button>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default AdminOrdersComp;
