import "./AddShop.scss";
import React, { useState } from "react";
import SidebarAdmin from "../../components/SidebarAdmin/SidebarAdmin";
import Navbar from "../../components/navbar/Navbar";
import Widget from "../../components/widget/Widget";
import TextField from "@mui/material/TextField";
import { Alert, Button } from "@mui/material";
import firebase from "../../utils/firebase";
import { Typography } from "@mui/material";
import Switch from "@mui/joy/Switch";
import Modal from "@mui/joy/Modal";
import ModalClose from "@mui/joy/ModalClose";
import Sheet from "@mui/joy/Sheet";
import LocationPiker from "../../components/locationPicker/locationPiker";
import Select from "react-select";

const AddShop = ({ google }) => {
  const options = [
    { label: "Austria", value: "EUR" },
    { label: "Belgium", value: "EUR" },
    { label: "Canada", value: "CAD" },
    { label: "Denmark", value: "DKK" },
    { label: "Egypt", value: "EGP" },
    { label: "Finland", value: "EUR" },
    { label: "France", value: "EUR" },
    { label: "Germany", value: "EUR" },
    { label: "Greece", value: "EUR" },
    { label: "Hongkong", value: "HKD" },
    { label: "Iceland", value: "ISK" },
    { label: "India", value: "INR" },
    { label: "Ireland", value: "EUR" },
    { label: "Italy", value: "EUR" },
    { label: "Japan", value: "JPY" },
    { label: "Kuwait", value: "KWD" },
    { label: "Liechtenstein", value: "CHF" },
    { label: "Luxembourg", value: "EUR" },
    { label: "Monaco", value: "EUR" },
    { label: "Netherlands", value: "EUR" },
    { label: "Norway", value: "NOK" },
    { label: "Poland", value: "PLN" },
    { label: "Portugal", value: "EUR" },
    { label: "Qatar", value: "QAR" },
    { label: "Russia", value: "RUB" },
    { label: "Saudi Arabia", value: "SAR" },
    { label: "Singapore", value: "SGD" },
    { label: "Spain", value: "EUR" },
    { label: "Sweden", value: "SEK" },
    { label: "Switzerland", value: "CHF" },
    { label: "Turkey", value: "TRY" },
    { label: "UAE", value: "AED" },
    { label: "United Kingdom", value: "GBP" },
    { label: "USA", value: "USD" },
  ];
  const [selectedOption, setSelectedOption] = useState(null);
  const [shopLogo, setShopLogo] = useState("");
  const [password, setPassword] = useState("");
  const [shopName, setShopName] = useState("");
  const [email, setEmail] = useState("");
  const [menuItems, setMenuItems] = useState([{ item: "", price: "" }]);
  const [stylists, setStylists] = useState([{ name: "", image: null }]);
  const [charges, setCharges] = useState([{ name: "", price: "" }]);
  const [reviews, setReviews] = useState(0);
  const [rating, setRating] = useState(5);
  const [checked, setChecked] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [two, setTwo] = useState("");
  const [three, setThree] = useState("");
  const [four, setFour] = useState("");
  const [vat, setVat] = useState(0);
  console.log("curr", selectedOption);
  const addShopHandler = async () => {
    if (!shopLogo) {
      alert("Please add shop logo!");
      return;
    }
    if (!password) {
      alert("Please enter the shop password!");
      return;
    }
    if (!email) {
      alert("Please enter the email address!");
      return;
    }
    if (menuItems[0].item === "" || menuItems[0].price === "") {
      alert("Please add atleast one menu item!");
      return;
    }
    if (stylists[0].name == "" || stylists[0].image === null) {
      alert("Please add atleast one stylist!");
      return;
    }
    if (charges[0].name == "" || charges[0].price === "") {
      alert("Please add atleast one charges item!");
      return;
    }
    if (selectedOption === null) {
      alert("Please select currency!");
      return;
    }
    if (!vat) {
      alert("Please add vat!");
      return;
    }
    if (checked && !two) {
      alert("Please enter two times / month subscription price!");
      return;
    }
    if (checked && !three) {
      alert("Please enter three times / month subscription price!");
      return;
    }
    if (checked && !four) {
      alert("Please enter four times / month subscription price!");
      return;
    }
    if (!selectedLocation) {
      alert("Please pick the shop location!");
      return;
    }
    console.log("email", email, password);
    await firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(async (user) => {
        const shopId = user.user.uid;
        await firebase.firestore().collection("Shops").doc(shopId).set({
          shopId: shopId,
          email: email,
          shopLogo: shopLogo,
          shopName: shopName,
          details: menuItems,
          charges: charges,
          stylists: stylists,
          dsp: checked,
          twoTimes: two,
          threeTimes: three,
          fourTimes: four,
          shopLocation: selectedLocation,
          reviews: reviews,
          rating: rating,
          currency: selectedOption,
          vat: vat,
          status: "open",
        });
      })
      .catch((err) => {
        alert("Err", err);
      });
    alert("Shop added successfully!");
  };

  const handleShopLogo = async (file) => {
    // Upload image to Firebase Storage
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(file.name);
    await imageRef
      .put(file)
      .then((res) => {
        console.log("done", res);
      })
      .catch((err) => {
        console.log("err", err);
      });

    // Get the image URL
    const imageUrl = await imageRef.getDownloadURL();
    setShopLogo(imageUrl);
  };
  const handleAddMenu = () => {
    const newItems = [...menuItems, { item: "", price: "" }];
    setMenuItems(newItems);
  };

  const handleInputChangeMenu = (index, field, value) => {
    const newItems = [...menuItems];
    newItems[index][field] = value;
    setMenuItems(newItems);
  };

  const handleAddStylist = () => {
    const newItems = [...stylists, { name: "", image: null }];
    setStylists(newItems);
  };

  const handleStylistNameChange = (index, value) => {
    const newItems = [...stylists];
    newItems[index].name = value;
    setStylists(newItems);
  };

  const handleStylistImageChange = async (index, file) => {
    const newItems = [...stylists];

    // Upload image to Firebase Storage
    const storageRef = firebase.storage().ref();
    const imageRef = storageRef.child(file.name);
    await imageRef
      .put(file)
      .then((res) => {
        console.log("done", res);
      })
      .catch((err) => {
        console.log("err", err);
      });

    // Get the image URL
    const imageUrl = await imageRef.getDownloadURL();

    newItems[index].image = imageUrl;
    setStylists(newItems);
  };
  const handleChargeInputChange = (index, field, value) => {
    const newItems = [...charges];
    newItems[index][field] = value;
    setCharges(newItems);
  };
  const handleAddCharges = () => {
    const newItems = [...charges, { name: "", price: "" }];
    setCharges(newItems);
  };
  return (
    <div className="home">
      <SidebarAdmin />
      <div className="homeContainer">
        {/* <Navbar /> */}
        {/* <div className="widgets">
          <Widget type="user" />
          <Widget type="order" />
          <Widget type="earning" />
          <Widget type="balance" />
        </div> */}

        <div className="listContainer">
          <div style={{ textAlign: "center" }} className="listTitle">
            <h1 style={{ textAlign: "center", color: "black" }}>
              Add New Shop
            </h1>
          </div>

          <Typography
            style={{
              marginLeft: "40%",
              marginTop: 10,
              marginBottom: 10,
              color: "red",
              fontWeight: "bold",
            }}
            level="h1"
          >
            Shop Logo
          </Typography>
          <TextField
            type="file"
            style={{
              width: "50%",
              marginLeft: "20%",
              marginTop: 10,
            }}
            id="outlined-basic"
            variant="outlined"
            accept="image/*"
            onChange={(e) => handleShopLogo(e.target.files[0])}
          />
          <TextField
            style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
            id="outlined-basic"
            label="Shop Password"
            variant="outlined"
            onChange={(event) => {
              setPassword(event.target.value);
            }}
            value={password}
          />
          <TextField
            style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
            id="outlined-basic"
            label="Shop Name"
            variant="outlined"
            onChange={(event) => {
              setShopName(event.target.value);
            }}
            value={shopName}
          />
          <TextField
            style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
            id="outlined-basic"
            label="Email Address"
            variant="outlined"
            onChange={(event) => {
              setEmail(event.target.value);
            }}
            value={email}
          />
          <Typography
            style={{
              marginLeft: "40%",
              marginTop: 10,
              marginBottom: 10,
              color: "red",
              fontWeight: "bold",
            }}
            level="h2"
          >
            Add Menu
          </Typography>
          {menuItems.map((item, index) => {
            return (
              <div key={index} style={{ flexDirection: "row" }}>
                <TextField
                  style={{ width: "24%", marginLeft: "20%", marginTop: 10 }}
                  id="outlined-basic"
                  label="Menu Item"
                  variant="outlined"
                  onChange={(e) =>
                    handleInputChangeMenu(index, "item", e.target.value)
                  }
                  value={item.item}
                />
                <TextField
                  type="number"
                  style={{ width: "24%", marginLeft: "2%", marginTop: 10 }}
                  id="outlined-basic"
                  label="Price"
                  variant="outlined"
                  onChange={(e) =>
                    handleInputChangeMenu(index, "price", e.target.value)
                  }
                  value={item.price}
                />
              </div>
            );
          })}

          <Button
            onClick={handleAddMenu}
            style={{ width: "50%", marginLeft: "20%", marginTop: 20 }}
            variant="contained"
          >
            Add More Menu Items
          </Button>
          <Typography
            style={{
              marginLeft: "40%",
              marginTop: 10,
              marginBottom: 10,
              color: "red",
              fontWeight: "bold",
            }}
            level="h1"
          >
            Add Stylists
          </Typography>
          {stylists.map((item, index) => {
            return (
              <div key={index} style={{ flexDirection: "row" }}>
                <TextField
                  style={{ width: "24%", marginLeft: "20%", marginTop: 10 }}
                  id="outlined-basic"
                  label="Stylist Name"
                  variant="outlined"
                  value={item.name}
                  onChange={(e) =>
                    handleStylistNameChange(index, e.target.value)
                  }
                />
                <TextField
                  type="file"
                  style={{ width: "24%", marginLeft: "2%", marginTop: 10 }}
                  id="outlined-basic"
                  variant="outlined"
                  accept="image/*"
                  onChange={(e) =>
                    handleStylistImageChange(index, e.target.files[0])
                  }
                />
                {/* {item.image && <img src={item.image} alt="Item" style={{}} />} */}
              </div>
            );
          })}

          <Button
            onClick={handleAddStylist}
            style={{ width: "50%", marginLeft: "20%", marginTop: 20 }}
            variant="contained"
          >
            Add More Stylist
          </Button>
          <Typography
            style={{
              marginLeft: "40%",
              marginTop: 10,
              marginBottom: 10,
              color: "red",
              fontWeight: "bold",
            }}
            level="h1"
          >
            Add Charges
          </Typography>
          {charges.map((item, index) => {
            return (
              <div key={index} style={{ flexDirection: "row" }}>
                <TextField
                  style={{ width: "24%", marginLeft: "20%", marginTop: 10 }}
                  id="outlined-basic"
                  label="Name of charge"
                  variant="outlined"
                  onChange={(e) =>
                    handleChargeInputChange(index, "name", e.target.value)
                  }
                  value={item.name}
                />
                <TextField
                  type="number"
                  style={{ width: "24%", marginLeft: "2%", marginTop: 10 }}
                  id="outlined-basic"
                  label="Price"
                  variant="outlined"
                  onChange={(e) =>
                    handleChargeInputChange(index, "price", e.target.value)
                  }
                  value={item.price}
                />
              </div>
            );
          })}

          <Button
            onClick={handleAddCharges}
            style={{ width: "50%", marginLeft: "20%", marginTop: 20 }}
            variant="contained"
          >
            Add More Charges
          </Button>
          <div style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}>
            <Select
              placeholder="Currency"
              defaultValue={selectedOption}
              onChange={setSelectedOption}
              options={options}
              isSearchable={true}
              getOptionLabel={(option) => option.label}
              getOptionValue={(option) => option.label + option.value}
              styles={{
                control: (baseStyles, state) => ({
                  ...baseStyles,
                  height: 55,
                }),
                menu: (provided, state) => ({
                  ...provided,
                  zIndex: 5, // or a higher value if necessary
                }),
              }}
            />
          </div>

          <TextField
            type="number"
            style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
            id="outlined-basic"
            variant="outlined"
            label="VAT"
            onChange={(event) => {
              setVat(event.target.value);
            }}
            value={vat}
          />
          <div style={{ width: "50%", marginLeft: "20%", marginTop: 20 }}>
            <Switch
              checked={checked}
              onChange={(event) => setChecked(event.target.checked)}
              color={checked ? "success" : "neutral"}
              variant={checked ? "solid" : "outlined"}
              endDecorator={
                checked
                  ? "Shop Included In DSP(drep Subscription Program)"
                  : "Shop Not Included In DSP(drep Subscription Program)"
              }
              slotProps={{
                endDecorator: {
                  sx: {
                    minWidth: 24,
                  },
                },
              }}
            />
          </div>
          {checked && (
            <div>
              <TextField
                type="number"
                style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
                id="outlined-basic"
                label="2 times/month price"
                variant="outlined"
                onChange={(event) => {
                  setTwo(event.target.value);
                }}
                value={two}
              />
              <TextField
                type="number"
                style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
                id="outlined-basic"
                label="3 times/month price"
                variant="outlined"
                onChange={(event) => {
                  setThree(event.target.value);
                }}
                value={three}
              />
              <TextField
                type="number"
                style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
                id="outlined-basic"
                label="4 times/month price"
                variant="outlined"
                onChange={(event) => {
                  setFour(event.target.value);
                }}
                value={four}
              />
            </div>
          )}
          <TextField
            type="number"
            style={{ width: "50%", marginLeft: "20%", marginTop: 20 }}
            id="outlined-basic"
            label="Shop Reviews"
            variant="outlined"
            onChange={(event) => {
              setReviews(event.target.value);
            }}
            value={reviews}
          />
          <TextField
            type="number"
            style={{ width: "50%", marginLeft: "20%", marginTop: 10 }}
            id="outlined-basic"
            label="Shop Ratings"
            variant="outlined"
            onChange={(event) => {
              setRating(event.target.value);
            }}
            value={rating}
          />
          <Button
            style={{
              display: "block",
              width: "50%",
              marginLeft: "20%",
              marginTop: 20,
            }}
            size="md"
            variant={"outlined"}
            color="success"
            onClick={() => {
              setOpen(true);
            }}
          >
            Pick up the shop location
          </Button>
          <Button
            variant="contained"
            style={{
              width: "50%",
              marginLeft: "20%",
              marginTop: 30,
              backgroundColor: "red",
            }}
            onClick={addShopHandler}
          >
            Add Shop
          </Button>
          <Modal
            aria-labelledby="modal-title"
            aria-describedby="modal-desc"
            open={open}
            onClose={() => setOpen(false)}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Sheet
              variant="outlined"
              sx={{
                width: "70%",
                borderRadius: "md",
                p: 3,
                boxShadow: "lg",
                height: 500,
              }}
            >
              <ModalClose
                variant="outlined"
                sx={{
                  top: "calc(-1/4 * var(--IconButton-size))",
                  right: "calc(-1/4 * var(--IconButton-size))",
                  boxShadow: "0 2px 12px 0 rgba(0 0 0 / 0.2)",
                  borderRadius: "50%",
                  bgcolor: "background.surface",
                }}
              />
              <Typography
                component="h2"
                id="modal-title"
                level="h4"
                textColor="inherit"
                fontWeight="lg"
                mb={1}
              >
                Please the shop location from map:
              </Typography>
              <LocationPiker
                selectedLocation={selectedLocation}
                setSelectedLocation={setSelectedLocation}
              />
            </Sheet>
          </Modal>
        </div>
      </div>
    </div>
  );
};

export default AddShop;
