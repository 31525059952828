import "./table.scss";
import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import firebase from "../../utils/firebase";
import { ManagerContext } from "../../context/managerContext";

const List = () => {
  const [orders, setOrders] = useState([]);
  const { manager } = useContext(ManagerContext);
  console.log("id", manager.id);
  console.log("or", orders);
  const fetchData = async () => {
    try {
      const db = firebase.firestore();
      const unsubscribe = db
        .collection("Orders")
        .orderBy("timeStamp")
        .onSnapshot((querySnapshot) => {
          const data = [];
          querySnapshot.forEach((documentSnapshot) => {
            data.push({
              id: documentSnapshot.id,
              ...documentSnapshot.data(),
            });
          });
          const myOrders = data.filter((item) => {
            return item.shop.shopId === manager.id;
          });
          let orderArray = myOrders.reverse();
          let sliceArray = orderArray.slice(0, 5);
          setOrders(sliceArray);
        });

      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching collection:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);
  const rows = [
    {
      id: 1143155,
      product: "Acer Nitro 5",
      img: "https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "John Smith",
      date: "1 March",
      amount: 785,
      method: "Cash on Delivery",
      status: "Approved",
    },
    {
      id: 2235235,
      product: "Playstation 5",
      img: "https://m.media-amazon.com/images/I/31JaiPXYI8L._AC_UY327_FMwebp_QL65_.jpg",
      customer: "Michael Doe",
      date: "1 March",
      amount: 900,
      method: "Online Payment",
      status: "Pending",
    },
    {
      id: 2342353,
      product: "Redragon S101",
      img: "https://m.media-amazon.com/images/I/71kr3WAj1FL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "John Smith",
      date: "1 March",
      amount: 35,
      method: "Cash on Delivery",
      status: "Pending",
    },
    {
      id: 2357741,
      product: "Razer Blade 15",
      img: "https://m.media-amazon.com/images/I/71wF7YDIQkL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "Jane Smith",
      date: "1 March",
      amount: 920,
      method: "Online",
      status: "Approved",
    },
    {
      id: 2342355,
      product: "ASUS ROG Strix",
      img: "https://m.media-amazon.com/images/I/81hH5vK-MCL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "Harold Carol",
      date: "1 March",
      amount: 2000,
      method: "Online",
      status: "Pending",
    },
    {
      id: 1143155,
      product: "Acer Nitro 5",
      img: "https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "John Smith",
      date: "1 March",
      amount: 785,
      method: "Cash on Delivery",
      status: "Approved",
    },
    {
      id: 1143155,
      product: "Acer Nitro 5",
      img: "https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "John Smith",
      date: "1 March",
      amount: 785,
      method: "Cash on Delivery",
      status: "Approved",
    },
    {
      id: 1143155,
      product: "Acer Nitro 5",
      img: "https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "John Smith",
      date: "1 March",
      amount: 785,
      method: "Cash on Delivery",
      status: "Approved",
    },
    {
      id: 1143155,
      product: "Acer Nitro 5",
      img: "https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "John Smith",
      date: "1 March",
      amount: 785,
      method: "Cash on Delivery",
      status: "Approved",
    },
    {
      id: 1143155,
      product: "Acer Nitro 5",
      img: "https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "John Smith",
      date: "1 March",
      amount: 785,
      method: "Cash on Delivery",
      status: "Approved",
    },
    {
      id: 1143155,
      product: "Acer Nitro 5",
      img: "https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "John Smith",
      date: "1 March",
      amount: 785,
      method: "Cash on Delivery",
      status: "Approved",
    },
    {
      id: 1143155,
      product: "Acer Nitro 5",
      img: "https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "John Smith",
      date: "1 March",
      amount: 785,
      method: "Cash on Delivery",
      status: "Approved",
    },
    {
      id: 1143155,
      product: "Acer Nitro 5",
      img: "https://m.media-amazon.com/images/I/81bc8mA3nKL._AC_UY327_FMwebp_QL65_.jpg",
      customer: "John Smith",
      date: "1 March",
      amount: 785,
      method: "Cash on Delivery",
      status: "Approved",
    },
  ];
  const [anchorEl, setAnchorEl] = useState(null);
  const handleCancel = async (item) => {
    await firebase.firestore().collection("Orders").doc(item.orderId).update({
      status: "cancelled",
    });
  };
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  const assignStylistHander = async (stylist, item) => {
    console.log("sst", stylist, item.orderId);
    handleClose();
    await firebase.firestore().collection("Orders").doc(item.orderId).update({
      status: "assigned",
      stylist: stylist,
    });
  };
  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Customer</TableCell>
            <TableCell className="tableCell">From Subscription</TableCell>
            <TableCell className="tableCell">Service</TableCell>
            <TableCell className="tableCell">Total Amount</TableCell>
            <TableCell className="tableCell">Payment Method</TableCell>
            <TableCell className="tableCell">Location</TableCell>
            <TableCell className="tableCell">Address</TableCell>
            <TableCell className="tableCell">Stylist</TableCell>
            <TableCell className="tableCell">Phone Number</TableCell>
            <TableCell className="tableCell">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((item) => (
            <TableRow key={item.shopId}>
              <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img
                    src={item?.user?.profileImage}
                    alt=""
                    className="image"
                  />
                  {item.user.name}
                </div>
              </TableCell>
              <TableCell className="tableCell">
                {item.fromSubscription ? "true" : "false"}
              </TableCell>

              <TableCell className="tableCell">
                {item.fromSubscription ? "Subscription" : item.menuItem.item}
              </TableCell>

              <TableCell className="tableCell">{item?.totalAmount}</TableCell>
              <TableCell className="tableCell">{item?.paymentMethod}</TableCell>
              <TableCell className="tableCell">
                <a
                  href={item?.user.locationLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  open location
                </a>
              </TableCell>
              <TableCell className="tableCell">
                <p style={{ fontSize: 9 }}>{item?.user.address}</p>
              </TableCell>
              <TableCell className="tableCell">
                {item.stylist.name ? item.stylist.name : "Not Specified"}
              </TableCell>
              <TableCell className="tableCell">
                {item.user.phoneNumber}
              </TableCell>
              <TableCell className="tableCell">
                <span className={`status ${item.status}`}>{item.status}</span>
              </TableCell>

              <TableCell className="tableCell">
                {item.status === "requested" ? (
                  <>
                    <Button
                      style={{ width: "10%", fontSize: 10 }}
                      variant="outlined"
                      onClick={handleClick}
                    >
                      assign
                    </Button>

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleClose}
                    >
                      {item.shop.stylists.map((stylist, index) => (
                        <MenuItem
                          key={index}
                          onClick={() => {
                            console.log("it", item.orderId);
                            assignStylistHander(stylist, item);
                          }}
                        >
                          {stylist.name}
                        </MenuItem>
                      ))}
                    </Menu>
                  </>
                ) : null}
              </TableCell>
              <TableCell className="tableCell">
                {item.status === "requested" ? (
                  <Button
                    style={{ width: "10%", fontSize: 10 }}
                    variant="outlined"
                    onClick={() => {
                      handleCancel(item);
                    }}
                  >
                    Cancel
                  </Button>
                ) : null}
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default List;
