import "./complaint.scss";
import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import firebase from "../../utils/firebase";

const Complaint = () => {
  const [complaints, setComplaints] = useState([]);
  const fetchData = async () => {
    try {
      const db = firebase.firestore();
      const unsubscribe = db
        .collection("Complaints") // Replace with the actual collection name
        .onSnapshot((querySnapshot) => {
          const data = [];
          querySnapshot.forEach((documentSnapshot) => {
            data.push({
              id: documentSnapshot.id,
              ...documentSnapshot.data(),
            });
          });

          setComplaints(data);
        });

      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching collection:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Shop</TableCell>
            <TableCell className="tableCell">Complaint</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {complaints.map((item) => (
            <TableRow key={item.id}>
              <TableCell style={{ width: "15%" }}>
                <div className="cellWrapper">
                  <img src={item?.shop?.shopLogo} alt="" className="image" />
                  {item.shop.shopName}
                </div>
              </TableCell>

              <TableCell className="tableCell">
                <p style={{ fontSize: 16, color: "orangered" }}>
                  {item?.complaint}
                </p>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Complaint;
