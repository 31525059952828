import "./sidebarAdmin.scss";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import StoreIcon from "@mui/icons-material/Store";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import AddBusinessIcon from "@mui/icons-material/AddBusiness";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import NotesIcon from "@mui/icons-material/Notes";
import { Link, useNavigate } from "react-router-dom";
import firebase from "../../utils/firebase";

const SidebarAdmin = () => {
  const navigate = useNavigate();
  const logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        navigate("/");
        alert("Logged Out Successfully");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <div className="sidebar">
      <div className="top">
        <span className="logo">drepAdmin</span>
      </div>
      <hr />
      <div className="center">
        <ul>
          <Link to="/shops" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Shops</span>
            </li>
          </Link>
          <Link to="/adminOrders" style={{ textDecoration: "none" }}>
            <li>
              <StoreIcon className="icon" />
              <span>Orders</span>
            </li>
          </Link>


          <Link to="/complaints" style={{ textDecoration: "none" }}>
            <li>
              <PersonOutlineIcon className="icon" />
              <span>complaints</span>
            </li>
          </Link>
          <Link to="/comments" style={{ textDecoration: "none" }}>
            <li>
              <NotesIcon className="icon" />
              <span>comments</span>
            </li>
          </Link>
          <Link to="/suggesstions" style={{ textDecoration: "none" }}>
            <li>
              <BookmarkIcon className="icon" />
              <span>Suggestions</span>
            </li>
          </Link>
          <Link to="/addshop" style={{ textDecoration: "none" }}>
            <li>
              <AddBusinessIcon className="icon" />
              <span>Add Shop</span>
            </li>
          </Link>
          <li style={{ marginTop: 400 }}>
            <ExitToAppIcon className="icon" />
            <span onClick={logoutHandler}>Logout</span>
          </li>
        </ul>
      </div>
      <div className="bottom"></div>
    </div>
  );
};

export default SidebarAdmin;
