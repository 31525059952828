import { createContext, useReducer } from "react";
import ManagerReducer from "./managerReducer";

const INITIAL_STATE = {
  manager: {},
};

export const ManagerContext = createContext(INITIAL_STATE);

export const ManagerContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(ManagerReducer, INITIAL_STATE);

  return (
    <ManagerContext.Provider value={{ manager: state.manager, dispatch }}>
      {children}
    </ManagerContext.Provider>
  );
};
