import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import "./orders.scss";
import Widget from "../../components/widget/Widget";
import Table from "../../components/table/Table";
import { Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import firebase from "../../utils/firebase";
import { ManagerContext } from "../../context/managerContext";

const Orders = () => {
  const [status, setStatus] = useState();
  const { manager } = useContext(ManagerContext);
  useEffect(() => {
    getStatus();
  }, []);
  const getStatus = async () => {
    await firebase
      .firestore()
      .collection("Shops")
      .doc(manager.id)
      .get()
      .then((res) => {
        let data = res.data();
        setStatus(data.status);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  const changeStatusHandler = async (st) => {
    await firebase
      .firestore()
      .collection("Shops")
      .doc(manager.id)
      .update({
        status: st,
      })
      .then(() => {
        setStatus(st);
        console.log("status updated successfully");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <div className="home">
      <Sidebar />
      <div className="homeContainer">
        <Navbar />
        <div className="widgets">
          <Widget type="order" />
          <Widget type="user" />
        </div>
        <h1 style={{ textAlign: "center", color: "red", marginBottom: 10 }}>
          {status}
        </h1>
        <div
          className="statusContainer"
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Button
            style={{ width: "20%", marginLeft: "15%" }}
            onClick={() => {
              changeStatusHandler("open");
            }}
            variant={status === "open" ? "contained" : "outlined"}
            size="large"
          >
            Open
          </Button>
          <Button
            style={{ width: "20%", marginLeft: "5%" }}
            onClick={() => {
              changeStatusHandler("busy");
            }}
            variant={status === "busy" ? "contained" : "outlined"}
            size="large"
          >
            Busy
          </Button>
          <Button
            style={{ width: "20%", marginLeft: "5%" }}
            onClick={() => {
              changeStatusHandler("close");
            }}
            variant={status === "close" ? "contained" : "outlined"}
            size="large"
          >
            Close
          </Button>
        </div>
        <div className="listContainer">
          <div className="listTitle">Orders</div>
          <Table />
        </div>
      </div>
    </div>
  );
};

export default Orders;
