import "./subs.scss";
import React, { useContext, useEffect, useState } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

import firebase from "../../utils/firebase";
import { ManagerContext } from "../../context/managerContext";

const Subs = () => {
  const [subs, setSubs] = useState([]);
  const { manager, dispatch } = useContext(ManagerContext);
  console.log("or", subs);
  const fetchData = async () => {
    try {
      const db = firebase.firestore();
      const unsubscribe = db
        .collection("Subscriptions") // Replace with the actual collection name
        .onSnapshot((querySnapshot) => {
          const data = [];
          querySnapshot.forEach((documentSnapshot) => {
            data.push({
              id: documentSnapshot.id,
              ...documentSnapshot.data(),
            });
          });
          const mySubs = data.filter((item) => {
            return item.shop.shopId === manager.id;
          });
          let totalSubs = mySubs.length;

          setSubs(mySubs);
        });

      return () => unsubscribe();
    } catch (error) {
      console.error("Error fetching collection:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Customer</TableCell>
            <TableCell className="tableCell">Total</TableCell>
            <TableCell className="tableCell">Remaining</TableCell>
            <TableCell className="tableCell">Total Amount</TableCell>
            <TableCell className="tableCell">Payment Method</TableCell>
            <TableCell className="tableCell">Location</TableCell>
            <TableCell className="tableCell">Address</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {subs.map((item) => (
            <TableRow key={item.shopId}>
              <TableCell className="tableCell">
                <div className="cellWrapper">
                  <img
                    src={item?.user?.profileImage}
                    alt=""
                    className="image"
                  />
                  {item.user.name}
                </div>
              </TableCell>
              <TableCell className="tableCell">{item.subscription}</TableCell>

              <TableCell className="tableCell">{item.remaining}</TableCell>

              <TableCell className="tableCell">{item.totalAmount}</TableCell>
              <TableCell className="tableCell">{item?.paymentMethod}</TableCell>
              <TableCell className="tableCell">
                <a
                  href={item?.user.locationLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  open location
                </a>
              </TableCell>
              <TableCell className="tableCell">
                <p style={{ fontSize: 9 }}>{item?.user.address}</p>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Subs;
