import "./navbar.scss";
import firebase from "../../utils/firebase";
import { useContext, useEffect, useState } from "react";
import { ManagerContext } from "../../context/managerContext";

const Navbar = () => {
  const { manager } = useContext(ManagerContext);
  const [shop, setShop] = useState({});
  const getShopData = async () => {
    await firebase
      .firestore()
      .collection("Shops")
      .doc(manager.id)
      .get()
      .then((res) => {
        let data = res.data();
        setShop(data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  useEffect(() => {
    getShopData();
  }, []);
  return (
    <div className="navbar">
      <div className="wrapper">
        <div
          style={{ flexDirection: "row", justifyContent: "space-between" }}
          className="items"
        >
          <div className="item">
            {shop ? (
              <>
                <h2>{shop?.shopName}</h2>
                {/* <h5 style={{ marginLeft: "50%" }}>
                  {shop?.reviews + " Reviews"}
                </h5>
                <h5 style={{ marginLeft: "50%" }}>
                  {shop?.rating + "  Rating"}
                </h5> */}
              </>
            ) : null}
          </div>
          <div style={{ marginLeft: "780%" }} className="item">
            <img src={shop?.shopLogo} alt="" className="avatar" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
