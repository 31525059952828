import Login from "./pages/login/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import AddShop from "./pages/addShop/AddShop";
import Subscriptions from "./pages/subscriptions/subscriptions";
import Shops from "./pages/shops/shops";
import Orders from "./pages/home/Orders";
import Suggesstions from "./pages/Suggesstions/Suggesstions";
import Complaints from "./pages/complaints/complaints";
import Comments from "./pages/comments/comments";
import AdminOrders from "./pages/adminOrders/AdminOrders";

function App() {
  return (
    <div className={"app"}>
      <BrowserRouter>
        <Routes>
          <Route path="/">
            <Route index element={<Login />} />
            <Route path="orders" element={<Orders />} />
            <Route path="addshop" element={<AddShop />} />
            <Route path="subscriptions" element={<Subscriptions />} />
            <Route path="shops" element={<Shops />} />
            <Route path="suggesstions" element={<Suggesstions />} />
            <Route path="complaints" element={<Complaints />} />
            <Route path="comments" element={<Comments />} />
            <Route path="adminOrders" element={<AdminOrders />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
