import React, { useState } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

const LocationPicker = ({ google, selectedLocation, setSelectedLocation }) => {
  const onMapClick = (_, __, event) => {
    const clickedLocation = {
      lat: event.latLng.lat(),
      lng: event.latLng.lng(),
    };
    setSelectedLocation(clickedLocation);
  };

  return (
    <div>
      {selectedLocation && (
        <div>
          <p>
            Latitude: {selectedLocation.lat}, Longitude: {selectedLocation.lng}
          </p>
        </div>
      )}

      <Map
        google={google}
        style={{ width: "95%", height: "450px", position: "relative" }}
        zoom={14}
        onClick={onMapClick}
        initialCenter={{ lat: 32.279604751810574, lng: 72.40280885281713 }} // Default initial position (San Francisco)
      >
        {selectedLocation && (
          <Marker
            position={{ lat: selectedLocation.lat, lng: selectedLocation.lng }}
            name="Selected Location"
          />
        )}
      </Map>
      {selectedLocation && (
        <div>
          <p>
            Latitude: {selectedLocation.lat}, Longitude: {selectedLocation.lng}
          </p>
        </div>
      )}
    </div>
  );
};

export default GoogleApiWrapper({
  apiKey: "AIzaSyD5IHjKGbiBS6nEIeHwm_6cJPeiUIt1WE8",
})(LocationPicker);
