import "./sidebar.scss";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import StoreIcon from "@mui/icons-material/Store";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import { Link, useNavigate } from "react-router-dom";
import firebase from "../../utils/firebase";
import { Alert } from "@mui/material";
import BookmarkIcon from "@mui/icons-material/Bookmark";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

const Sidebar = () => {
  const navigate = useNavigate();
  const logoutHandler = async () => {
    await firebase
      .auth()
      .signOut()
      .then(() => {
        navigate("/");
        alert("Logged Out Successfully");
      })
      .catch((err) => {
        console.log("err", err);
      });
  };
  return (
    <div className="sidebar">
      <div className="top">
        <span className="logo">drepManager</span>
      </div>
      <hr />
      <div className="center">
        <ul>
          <Link to="/orders" style={{ textDecoration: "none" }}>
            <li>
              <ShoppingCartIcon className="icon" />
              <span>Orders</span>
            </li>
          </Link>
          <Link to="/subscriptions" style={{ textDecoration: "none" }}>
            <li>
              <BookmarkIcon className="icon" />
              <span>Subscriptions</span>
            </li>
          </Link>

          <li style={{ marginTop: 500 }}>
            <ExitToAppIcon className="icon" />
            <span onClick={logoutHandler}>Logout</span>
          </li>
        </ul>
      </div>
      <div className="bottom"></div>
    </div>
  );
};

export default Sidebar;
