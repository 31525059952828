import SidebarAdmin from "../../components/SidebarAdmin/SidebarAdmin";
import Navbar from "../../components/navbar/Navbar";
import "./Suggesstions.scss";
import Widget from "../../components/widget/Widget";
import Shop from "../../components/shop/shop";
import Suggesstion from "../../components/suggesstion/suggesstions";

const Suggesstions = () => {
  return (
    <div className="home">
      <SidebarAdmin />
      <div className="homeContainer">
        {/* <Navbar /> */}
        {/* <div className="widgets">
          <Widget type="earning" />
          <Widget type="balance" />
        </div> */}

        <div className="listContainer">
          <div className="listTitle">All Suggestions</div>
          <Suggesstion />
        </div>
      </div>
    </div>
  );
};

export default Suggesstions;
